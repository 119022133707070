import { Route, Routes } from "react-router-dom"
import { Landing, Registration, About } from "./features"

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="register" element={<Registration />} />
      {/* <Route path="about" element={<About />} /> */}

    </Routes>
  )
}

export default App