import React from "react";

const GradientText = ({ children }) => {
  return (
    <div className="bg-clip-text text-transparent bg-gradient-to-r from-customRed to-customOrange">
      {children}
    </div>
  );
};

export default GradientText;
